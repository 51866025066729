jQuery('.fsvb').each(function() {

    // get the attributes from background video section
    let wpbakery_video_data_attribute = jQuery(this).attr('data-vc-video-bg');
    let blm_background_overlay_color = jQuery(this).attr('blm-background-overlay-color');
    let blm_background_overlay_opacity = jQuery(this).attr('blm-background-overlay-opacity');

    // prepend wp bakery's background image HTML and pass over the local video file to the iframe template
    jQuery(this).prepend('<div class="vc_video-bg vc_hidden-xs"><iframe id="fullscreen-iframe" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="1" class="inner" frameborder="0" height="100%" width="100%" scrolling="no" id="widget2" name="widget2" src="https://www.beaverlakemarina.com/wp-content/themes/beaver-lake-marina-child/inc/local-video-background.php?background_video_url=' + wpbakery_video_data_attribute + '&background_overlay_color=' + blm_background_overlay_color + '&background_overlay_opacity=' + blm_background_overlay_opacity + '" title="BLM 1080 high" width="100%"></iframe></div>');

});

jQuery(document).ready(function() {

    // get the height of the element that will set the height for the matched elements
    const height_setter = jQuery('#height-setter').height();

    // set the height of the matched elements from the height setter element
    jQuery('.height-setter').css({
        'min-height':height_setter
    });

    // https://www.beaverlakemarina.com/inventory/?watercraft-year=any&watercraft-type=any&watercraft-condition=new

    let inventory_filter_param = getParameterByName('filter');

    if (inventory_filter_param) {
    
        let linked_inventory_filter_params = '?watercraft-year=' + getParameterByName('year') + '&watercraft-type=' + getParameterByName('type') + '&watercraft-condition=' + getParameterByName('condition');

        ajax_refresh_inventory(linked_inventory_filter_params);

    }

    console.log(inventory_filter_param);

});

/****************************************
Social Share JS, runs on click of element
on posts - include infogroup-includes/social-share.php
to add the accompanying HTML

Facebook still requires the Open Graph 
meta tags to populate the correct content
in the share window. This is included by Yoast SEO,
otherwise the OG meta tags need to be manually included
****************************************/

jQuery('.facebook-share').on('click', function() {
    var $url = jQuery(this).attr('data-url');
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + $url, "facebookWindow", "height=380,width=660,resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0");
    return false;
});

jQuery('.twitter-share').on('click', function() {
    var $this = jQuery(this),
        $url = $this.attr('data-url'),
        $title = $this.attr('data-title');

    window.open('http://twitter.com/intent/tweet?text=' + $title + ' ' + $url, "twitterWindow", "height=380,width=660,resizable=0,toolbar=0,menubar=0,status=0,location=0,scrollbars=0");
    return false;
});

if (wordpress_page_id === '79') {

    const website_contact_type = getParameterByName('type');

    switch (website_contact_type) {
        case 'Quote Request':
            document.getElementById('input_1_6').selectedIndex = '2';
            break;
        case 'Service Request':
            document.getElementById('input_1_6').selectedIndex = '3';
            break;
        case 'Trade Valuation Request':
            document.getElementById('input_1_6').selectedIndex = '4';
            break;
        default:
            document.getElementById('input_1_6').selectedIndex = '1';
    }

}

// change the mobile menu icon when it opens and closes
jQuery('.ubermenu-responsive-toggle').on('ubermenutoggledopen', function(e){
    jQuery(this).find('i').removeClass('fa-bars').addClass('fa-times');
});

jQuery('.ubermenu-responsive-toggle').on('ubermenutoggledclose', function(e){
    jQuery(this).find('i').removeClass('fa-times').addClass('fa-bars');
});